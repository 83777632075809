import React from 'react';
import AdventureFilter from '../components/heroheader/adventurefilter';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"

const Container = styled.div`
  display: grid;
  background-position-x: center;
  background-size: cover;
  position: relative;
  > {
  }

  @media screen and (max-width: 767px) {
    min-height: 270px;
    margin-bottom: 32px;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
    min-height: 480px;
    background-position-y: center;
  }
  @media screen and (min-width: 960px) {
    margin-left: ${props => (props.fullWidth ? '0' : '250px')};
    height: 550px;
    margin-bottom: 0;
  }
  @media screen and (min-width: 1440px) {
    height: 650px;
  }
`;

const Heading = styled.div`
  padding: 20px 32px;
  display: inline-block;
  position: relative;
  height: 70px;
  grid-area: 1/1;
  position: relative;
  display: grid;


  @media screen and (min-width: 1440px) {
    padding: 64px 0 0 320px;
  }

  h1 {
    display: inline-block;
    width: 440px;
    padding: 12px;
    line-height: 42px;
    font-size: 42px;
    font-family: proxima-nova, sans-serif;
    font-display: swap;
    color: #fff;
    background: #0094a5;

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      font-size: 26px;
      line-height: 26px;
    }
  }

  h2 {
    width: 420px;
    margin: 20px;
    padding: 12px;
    color: #fff;
    background: #0094a5;
  }

  @media screen and (max-width: 767px) {
    display: block;
    padding: 0 16px 8px;
  }
`;


const NotFoundPage = () => {
  return (
    <Layout title={'Not Found'}>
      <Container className="hero" fullWidth={true}>
        <StaticImage
          style={{
            gridArea: "1/1",
            // You can set a maximum height for the image, if you wish.
            // maxHeight: 600,
          }}
          layout="fullWidth"
          // You can optionally force an aspect ratio for the generated image
          aspectRatio={3 / 1}
          // This is a presentational image, so the alt should be an empty string
          alt=""
          // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash
          src={
            "../../static/images/404.jpeg"
          }
          formats={["auto", "webp", "avif"]}
        />
        <Heading>
          <h1>Destination Unknown</h1>
          <h2>Error 404</h2>
        </Heading>
        <AdventureFilter />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;
